.public-ui {
  .navbar {
    .dropdown-menu {
      .link-list-wrapper {
        ul li {
          a {
            span,
            i,
            h3 {
              color: $body-color;
            }
            &.list-item.active {
              span,
              i,
              h3 {
                color: $red-ergo;
              }
            }
          }
        }
      }
    }
  }
}
