.it-header-center-wrapper {
  background: $white;
  .it-header-center-content-wrapper {
    .it-brand-wrapper a .icon {
      width: 73px;
      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        width: 124px;
      }
    }
    .it-search-wrapper a.rounded-icon {
      svg {
        fill: #fff;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          fill: $red-ergo;
        }
      }
    }
    .it-right-zone {
      span {
        color: $body-color;
      }
    }
  }
}
