.block.image-text-block {
  .card-wrapper {
    h2,
    h3,
    h4,
    h6 {
      font-weight: normal;
    }
  }
}
