.it-header-slim-wrapper {
  background: $darkred-ergo;

  .it-header-slim-wrapper-content {
    a.navbar-brand {
      &::before {
        display: inline-block;

        width: 200px;
        height: 40px;
        filter: brightness(0) invert(1);

        background-image: url('../../../images/logo-regione-emilia-romagna.png');
        background-repeat: no-repeat;
        background-size: cover;

        content: '';
        vertical-align: text-bottom;
      }
    }
    .it-header-slim-right-zone {
      .btn-icon.btn.btn-primary {
        background: $darkred-ergo;
        color: #fff !important;
      }
    }
    .navbar-brand {
      font-weight: $font-weight-normal;
    }
  }
}
