body.cms-ui,
body.cms-ui .public-ui {
  a {
    color: $link-color-ergo;
    text-decoration-color: $link-color-ergo;

    &:hover {
      color: $link-hover-color-ergo;
    }
  }
  .color-list-widget {
    button {
      &.default {
        background: rgba(0, 0, 0, 0.5);
        &.active,
        &:hover {
          background: rgba(0, 0, 0, 0.5) !important;
        }
      }
      &.opacity {
        background: rgba(0, 0, 0, 0.3);
        &.active,
        &:hover {
          background: rgba(0, 0, 0, 0.3) !important;
        }
      }
      &.no_opacity {
        background: none;
        &.active,
        &:hover {
          background: none !important;
        }
      }
      &.dark_font {
        background: $body-color;
        &.active,
        &:hover {
          background: $body-color !important;
        }
      }
      &.light_font {
        background: none;
        &.active,
        &:hover {
          background: none !important;
        }
      }
      &:hover {
        outline: 1px solid $secondary !important;
      }
    }
  }
}
