.block.iconBlocks {
  .container {
    &.transparent,
    &.primary {
      .title,
      .description {
        color: $body-color;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $body-color;
        }
        a {
          color: $link-color-ergo;
          &:hover {
            color: $link-hover-color-ergo;
          }
        }
      }
    }
    &.secondary {
      .block-header {
        .title,
        .description {
          color: #fff;
        }
      }
    }
  }
  .card {
    .iconblock-icon {
      .icon {
        fill: $red-ergo;
      }
    }
  }
  .link-button {
    .btn.btn-tertiary {
      background-color: $red-ergo;
      &:hover {
        background-color: $darkred-ergo;
      }
      color: #fff !important;
    }
  }
}
