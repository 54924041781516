//site
$primary-h: 39 !default;
$primary-s: 100 !default;
$primary-b: 96 !default;

$primary: #f59e00;
$secondary: #1b6997;
$tertiary: $primary;

//ERGO COLOR PALLET
$red-ergo: #a62f13;
$darkred-ergo: darken($red-ergo, 5%);
$darkblue-ergo: darken($secondary, 5%);
$gray-900: #212529;
$bg-light-gray: #f0edeb; //color "sfondo sezioni"

//GDPR
$gdpr-accept-all: $primary;
/*colori com'erano prima della v7.12.0, senza il verde/rosso*/
$gdpr-toggle-checked: $primary;
$gdpr-toggle: rgb(149, 149, 149);
$gdpr-toggle-border: #00000050;

//texts
$body-color: $gray-900;
$primary-text: $body-color;
$header-center-text-color: $red-ergo;
$headings-color: $body-color;
$dropdown-custom-button-color: $darkred-ergo;
$link-color-ergo: $secondary;
$link-hover-color-ergo: darken($secondary, 20%);

//navmenu
$header-center-bg-color: $primary;
