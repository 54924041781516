.chip,
.public-ui .chip {
  &.chip-primary {
    background-color: transparent;
    border-color: $secondary;
    color: $secondary;

    & > .chip-label {
      color: $secondary;
    }

    &:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
  }
  &.chip-secondary {
    background-color: transparent;
    border-color: $red-ergo;
    color: $red-ergo;

    & > .chip-label {
      color: $red-ergo;
    }

    &:hover {
      background-color: $red-ergo;
      border-color: $red-ergo;
    }
  }
}
