.sliderTemplate {
  .slick-arrow {
    .icon {
      width: 25px;
      height: 40px;
      color: lighten($body-color, 40%);
      fill: lighten($body-color, 40%);
    }
    &:hover .icon {
      color: $body-color;
      fill: $body-color;
    }
  }
}
