body.contenttype-faqfolder {
  .collapse-div {
    .collapse-header {
      button {
        color: $secondary !important;
        &.collapsed {
          color: $primary-text !important;
        }
      }
    }
  }
}
