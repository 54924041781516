:root {
  --info-block-primary-color: #f0c250;
  --info-block-secondary-color: #eb973f;
  --info-block-tertiary-color: #a32219;
  --info-block-tertiary-font-color-with-bg: #fff;
}

body.cms-ui {
  .infoblock {
    .color-list-widget {
      .ui.circular.button.primary {
        background-color: #f0c250;
        &:hover {
          background-color: darken(#f0c250, 10%);
        }
      }

      .ui.circular.button.secondary {
        background-color: #eb973f;
        &:hover {
          background-color: darken(#eb973f, 10%);
        }
      }
      .ui.circular.button.tertiary {
        background-color: #a32219;
        &:hover {
          background-color: darken(#a32219, 10%);
        }
      }
    }
  }
}
