.card-wrapper {
  .card-body {
    .card-title {
      color: $body-color !important;
      a {
        color: $body-color !important;
      }
    }
  }
  .card-bg {
    &.primary {
      .card-body {
        .card-title,
        .card-text {
          color: $body-color !important;
        }
      }
    }
  }
  .card-bg {
    &.secondary {
      a:hover {
        text-decoration-color: #fff;
      }
      .card-body {
        .card-title,
        .card-text {
          color: #fff !important;
        }
      }
    }
  }
}
//blocks with the background blue/secondary option
.bg-secondary {
  .card-wrapper {
    .card-body {
      .card-title {
        color: #fff !important;
        a {
          color: #fff !important;
        }
      }
    }
  }
}
