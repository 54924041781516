.block.cta-block {
  .cta-block-wrapper {
    background-color: $red-ergo;
    &.has-image {
      background-color: unset;
      &.default {
        picture::after {
          background: rgba(0, 0, 0, 0.5);
        }
      }
      &.opacity {
        picture::after {
          background: rgba(0, 0, 0, 0.3);
        }
      }
      &.no_opacity {
        picture::after {
          background: none;
        }
      }
    }
    .cta-tile-text {
      &.dark_font {
        &,
        h3,
        h2 {
          color: $body-color;
        }
        a.btn-outline-info {
          box-shadow: inset 0 0 0 1px $body-color;
          color: $body-color;
        }
      }
      &.light_font {
        &,
        h3,
        h2 {
          color: #fff;
        }
      }
      &,
      h3 {
        color: #fff;
      }

      a.btn-outline-info {
        box-shadow: inset 0 0 0 1px #fff;
        color: #fff;
        padding: 18px 60px;
        font-weight: bold;
        font-size: 1rem;

        &:hover,
        &:focus {
          background: #fff;
          color: #000;
        }
      }
    }
  }
}
