@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    &.it-navscroll-wrapper {
      .link-list-wrapper {
        ul li a.active {
          border-color: $secondary;
          span {
            color: $secondary;
          }
        }
      }
    }
  }
}
// these elements below belong to linklist.scss by default. Added here for convenience.
.link-list-wrapper ul li a {
  &:hover:not(.disabled) span {
    color: $primary-text;
  }
  span {
    color: $secondary;
  }
}
