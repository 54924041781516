.it-header-navbar-wrapper {
  .navbar {
    .menu-wrapper {
      a.nav-link {
        color: $body-color !important;
        svg.megamenu-toggle-icon {
          fill: $body-color !important;
        }
      }

      .navbar-secondary.navbar-nav {
        a.megamenu.nav-link {
          word-break: normal;
        }
      }
    }
  }
}
