.it-footer {
  .it-footer-main {
    background-color: $red-ergo;
    .it-brand-wrapper {
      display: flex;
      justify-content: space-between;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex-direction: column;
        .logo-er-footer {
          margin-top: 1em;
        }
      }
      .logo-er-footer {
        filter: brightness(0) invert(1);
      }
      .it-brand-text {
        h2,
        h3 {
          color: #fff;
        }
      }
    }
  }
  .it-footer-small-prints {
    background-color: $darkred-ergo;
  }
}
