.it-brand-text {
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $body-color;
  }
}

:not(box-slide-up) {
  a {
    color: $secondary;
    &:hover {
      color: $darkblue-ergo;
    }
    &.read-more {
      color: $secondary;
      &:hover {
        color: darken($secondary, 10%);
      }
      .icon {
        fill: $secondary;
        &:hover {
          color: darken($secondary, 10%);
        }
      }
    }
    &.btn-primary,
    &.btn-tertiary {
      color: $body-color !important;
    }
  }
}
button.custom-navbar-toggler {
  svg.icon {
    fill: $red-ergo;
  }
}
.text-serif {
  font-family: 'Titillium Web', Georgia, serif !important;
}

//Title card

.card-title {
  a {
    color: $body-color;
  }
}

.genericcard {
  .card-title {
    a {
      color: $body-color;
    }
  }
}

//correct texts made using only title tags inside empty rows in draftjs
h2,
h3,
h4,
h5,
h6 {
  min-height: 1em;
}
