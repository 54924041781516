.gdpr-privacy-banner {
  .gdpr-privacy-content {
    .description {
      a {
        color: #a62f13;
        &:hover {
          color: darken(#a62f13, 20%);
        }
      }
    }
  }
}
